import React, { Component, Fragment } from 'react';
import { CssBaseline, AppBar, Toolbar, IconButton, Typography, LinearProgress } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import LogoutIcon from '@material-ui/icons/SettingsPower';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';
import { drawerWidth } from './components';
import PropTypes from 'prop-types';
import Helper from '../utils/Helper'
import { Redirect } from 'react-router'


const styles = theme => ({
	appBar: {
		marginLeft: drawerWidth,
		[theme.breakpoints.up('md')]: {
			width: `calc(100% - ${drawerWidth}px)`,
		},
	},
	menuButton: {
		marginRight: 20,
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	grow: {
		flexGrow: 1,
	},
});

class Header extends Component {
	state = {
		logedOut: false,
		isLoading: false,
	}

	logOut = () => {
		Helper.logOut();
		this.setState({ logedOut: true });
	}

	render() {
		let { classes, handleDrawerToggle, title } = this.props;
		if (this.state.logedOut) return <Redirect to={'/login'} />
		return (
			<Fragment>
				<CssBaseline />
				<AppBar position="fixed" className={classes.appBar}>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="Open drawer"
							onClick={handleDrawerToggle}
							className={classes.menuButton}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" color="inherit" noWrap className={classes.grow}>
							{title}
						</Typography>
						<IconButton color="inherit" onClick={this.logOut}>
							<LogoutIcon />
						</IconButton>
					</Toolbar>
					{
						this.state.isLoading &&
						<LinearProgress color="secondary" />
					}
				</AppBar>
			</Fragment>
		);
	}
}


Header.propTypes = {
	classes: PropTypes.object.isRequired,
	// Injected by the documentation to work in an iframe.
	// You won't need it on your project.
	container: PropTypes.object,
	theme: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles, { withTheme: true })(Header));