import React, { Component } from 'react';
import { AppBar, Toolbar, Button } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import FilterIcon from '@material-ui/icons/FilterList'
import moment from 'moment';
import "moment/locale/ar-ma"
moment.locale("ar-ma");

let today = new moment.utc().endOf('day');

class Filters extends Component {
  state = {
    // The first commit of Material-UI
    interval: {
			from: today.clone().subtract(1, 'month').startOf('day'),
			to: today
		},
  };

  handleDateChange = (date, field) => {
    let { interval } = this.state;
    interval[field] = date
    this.setState({ interval }); 
  };

  render() {
    const { submit } = this.props;
    const { interval } = this.state;
    return (
      <AppBar position="static" color="default">
        <Toolbar variant="dense">
          <div style={{ marginRight: 'auto' }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                margin="dense"
                label="من"
                value={interval.from}
                onChange={d => this.handleDateChange(d, 'from')}
                maxDate={interval.to}
                style={{margin: '8px 10px'}}
                format={"YYYY-MM-DD"}
                okLabel={"تأكيد"}
                todayLabel={"اليوم"}
                cancelLabel={"إلغاء"}
                />
              <DatePicker
                margin="dense"
                label="إلى"
                value={interval.to}
                minDate={interval.from}
                maxDate={today}
                onChange={d => this.handleDateChange(d, 'to')}
                style={{margin: '8px 10px'}}
                format={"YYYY-MM-DD"}
                okLabel={"تأكيد"}
                todayLabel={"اليوم"}
                cancelLabel={"إلغاء"}
                />
            </MuiPickersUtilsProvider>

            <Button variant="contained" 
              onClick={ () => { if( submit ) submit.call(null,interval);} }
              color="primary" 
              style={{margin: '8px 10px'}}
              >
              <FilterIcon /> 
              فلترة
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    )
  }
}

export default Filters;