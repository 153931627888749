import React, { Component, Fragment } from 'react';
import { Grid, LinearProgress, Typography, Button } from '@material-ui/core';
import withRoot from '../../withRoot';
import Helper from '../../utils/Helper'
import { FieldSet } from 'zido-world-material-components'
import { green, orange, blue, purple } from '@material-ui/core/colors';
import { withRouter } from 'react-router'
import { ClipBoardCopy } from 'zido-world-material-components'


class Profile extends Component {

  state = {
    isLoading: false,
    lists: {
      general: [],
      subscription: [],
    },
    kids: [],
    loadingKids: false,
  }

  headlines = {
    general: {
      firstName: 'الاسم الشخصي',
      middleName: 'الاسم الأوسط',
      lastName: 'الاسم العائلي',
      email: function (item) {
        return {
          label: 'البريد الإلكتروني',
          value: <Typography variant="subtitle2" style={{ color: blue[900] }} >
            {item}
            <ClipBoardCopy text={item} />
          </Typography>
        }
      },
      country: 'البلد',
      region: 'المدينة / الجهة',
      dateOfBirth: (item) => {
        return {
          label: 'تاريخ الميلاد',
          value: new Date(item).getFullYear(),
        }
      },
    },
    subscription: {
      createdAt: (item) => {
        return {
          label: 'تاريخ الاشتراك',
          value: item ? new Date(item).toLocaleDateString() : '',
        }
      },
      lastSeen: (item) => {
        return {
          label: 'آخر ظهور',
          value: item ? new Date(item).toLocaleDateString() : '',
        }
      },
      status: (item) => {
        return {
          label: 'وضعية الحساب',
          value: item === 'active' ?
            <Typography variant="subtitle2" style={{ color: green[900] }}>
              مدفوع
                    </Typography>
            : <Typography variant="subtitle2" style={{ color: orange[900] }}>
              مجاني
                </Typography>
        }
      },
      transactions: (item) => {
        let retObj = {
          label: 'الاشتراك',
          value: '-',
        }
        if (item && item.length > 0) {
          let { pkg } = item.pop()
          retObj.value = (pkg === "quarterly") ? '3 أشهر' : 'سنوي';
        }
        return retObj;
      },
      expires: (item) => {
        return {
          label: 'انتهاء الصلاحية',
          value: item ? new Date(item).toLocaleDateString() : '',
        }
      }
    },
    kids: {
      id: (item) => {
        return {
          options: {
            actions: <Button size="small" fullWidth
              color="primary" variant="contained"
              onClick={() => this.link(`/accounts/${this.props.id}/report/${item}`)} >التقرير الكامل</Button>
          }
        }
      },
      displayName: 'الاسم',
      gender: (item) => {
        return {
          label: 'الجنس',
          value: item === "male" ? 'ولد' : 'بنت',
        }
      },
      level: (item) => {
        return {
          label: 'المستوى',
          value: item === 1 ? 'الأول' :
            item === 2 ? 'الثاني' : 'الثالث',
          options: {
            color: item === 1 ? green[50] :
              item === 2 ? orange[50] : purple[50],
          }
        }
      },
      lastSeen: (item) => {
        return {
          label: 'آخر ظهور',
          value: item ? new Date(item).toLocaleDateString() : '',
        }
      },
    }
  }


  buildRows = (set, data) => {
    let row = {
      options: {},
      list: [],
    };
    Object.keys(set).forEach(item => {
      if (typeof set[item] === 'string')
        row.list.push({
          label: set[item],
          value: data[item],
        })
      if (typeof set[item] === 'function') {
        let { label, value, options } = set[item].call(this, data[item])
        row.options = {
          ...row.options,
          ...options,
        }
        if (label && value) row.list.push({ label, value });
      }
    })
    return row;
  }

  link = (goTo) => {
    let { history } = this.props;
    history.push(goTo);
  }

  componentDidMount() {
    Helper.ApiRequest(
      `/accounts/${this.props.id}`
    ).then(data => {
      let { general, subscription } = this.headlines;
      let lists = {
        general: this.buildRows(general, data),
        subscription: this.buildRows(subscription, data),
      }
      this.setState({ lists, loadingKids: true })
      Helper.ApiRequest(
        `/accounts/${this.props.id}/reports`
      ).then(data => {
        let kids = []
        data.forEach(row => {
          let lItem = this.buildRows(this.headlines.kids, row);
          // lItem.list.push({
          //   label: 'رابط الدخول',
          //   value: <ClipBoardCopy text={`https://kids.zidoworld.com/login/${row.username}/${row.authToken}`} />
          // })
          kids.push(lItem);
        })
        this.setState({ kids, loadingKids: false });
      })
    })
  }

  getKids = () => {
    let cpmt = [];
    this.state.kids.forEach((kid, idx) => {
      cpmt.push(
        <Grid item md key={idx}>
          <FieldSet
            key={idx}
            title={'بطاقة الطفل'}
            list={kid.list}
            {...kid.options}
          // color={idx % 2 === 0 ? orange[50] : purple[50]}
          />

        </Grid>
      )
    })
    return cpmt;
  }

  render() {
    if (this.state.isLoading) return <LinearProgress />
    return (
      <Fragment>
        <Typography variant="h2">
          <Grid container>
            <Grid item xs>
              <FieldSet
                title={'معلومات العميل'}
                list={this.state.lists.general.list}
              />
            </Grid>
            <Grid item xs>
              <FieldSet
                title={'معلومات الاشتراك'}
                list={this.state.lists.subscription.list}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <FieldSet
                title={'معلومات الأبناء'} >
                {
                  this.state.loadingKids ? <LinearProgress />
                    : <Grid container>
                      {this.getKids()}
                    </Grid>
                }
              </FieldSet>
            </Grid>
          </Grid>
        </Typography>
      </Fragment>
    );
  }
}

export default  withRouter(withRoot(Profile));


