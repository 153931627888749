import React, { Component } from 'react';
import { Switch, Route, Redirect} from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { 
  SignIn,
  Reports,
  Customers,
  Publications,
  DailyReports,
} from './pages';
import Helper from './utils/Helper'

const Routes = () => (
    <Switch>
      <Route exact path='/login' component={SignIn}/>
      <PrivateRoute exact path='/customers/:tab?' component={Customers} />
      <PrivateRoute exact path='/publications' component={Publications} />
      <PrivateRoute exact path='/daily-reports' component={DailyReports} />
      <PrivateRoute exact path='/:tab?/:id?/:report?/:kid?' component={Reports} />
      {/* <Route component={NotFound} /> */}
    </Switch>
)

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route {...rest} render={props => (
     Helper.isAuthenticated() ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}/>
    )
  )}/>
)

class Routing extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes/>
      </BrowserRouter>
    );
  }
}

export default Routing;
