import React, { Component, Fragment } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import withRoot from '../../withRoot';
import { DashboardCards } from 'zido-world-material-components'
import Helper from '../../utils/Helper'
import Filters from './Filters'
import moment from 'moment';

let today = new moment.utc().endOf('day');

class Dashboard extends Component {
	// إدارة علاقات العملاء - عالم زيدو

	indexes = [{
		title: 'تسجيلات اليوم',
		index: "today",
	}, {
		title: 'تسجيلات أمس',
		index: "yesterday",
	}, {
		title: 'عدد العملاء',
		index: "total",
	}, {
		title: 'الحسابات المغلقة',
		index: "unsubscribes",
	}, {
		title: 'الحسابات المدفوعة',
		index: "active",
	}, {
		title: 'الحسابات المجانية',
		index: "suspended",
	}, {
		title: 'الحسابات غير المفعلة',
		index: "inactive",
	}, {
		title: 'مجموع الأطفال',
		mainIndex: 'kids',
		list: [
			{
				label: 'م. الأول',
				index: "level1",
			},
			{
				label: 'م. الثاني',
				index: "level2",
			},
			{
				label: 'م. الثالث',
				index: "level3",
			}
		],
	},
	]

	state = {
		cards: [],
		interval: {
			from: today.clone().subtract(1, 'month').startOf('day'),
			to: today
		},
		isLoading: false
	}

	componentDidMount() {
		this.refreshList();
	}

	refreshList() {
		let { interval } = this.state;
		let url = `/crm/reports`;
		if (interval)
		url += `/${interval.from.toISOString()}/${interval.to.toISOString()}`;
		// debugger;
		this.setState({ cards:[] });
		Helper.ApiRequest(
			url
		).then(data => {
			let cards = []
			this.indexes.forEach(item => {
				let { title, index, mainIndex } = item;
				if (index)
					cards.push({
						title,
						value: data[index],
					})
				if (mainIndex) {
					let list = [];
					item.list.forEach(it => {
						list.push(
							{
								label: it.label,
								value: data[mainIndex][it.index],
							}
						)
					});
					cards.push({
						title: title,
						list,
					})
				}
			})
			this.setState({ cards, isLoading: false });
		})
	}

	submit = (interval) => {
		this.setState({interval},() => this.refreshList());
	}

	render() {
		return (
			<Fragment>
				<Filters submit={this.submit} />
				<Grid container >
					{
						this.state.cards.length === 0 &&
						<Grid item xs>
							<LinearProgress />
						</Grid>
					}
					{
						this.state.cards.map((card, idx) =>
							<Grid item md={3} key={idx} sm={4} xs={6}>
								<DashboardCards
									{...card}
								/>
							</Grid>
						)
					}
				</Grid>
			</Fragment>
		);
	}
}

export default withRoot(Dashboard);

