import React, { Component, Fragment } from 'react';
import Layout from '../layout';
import { Typography, Paper, LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import { MenuTabs } from 'zido-world-material-components';


const styles = theme => ({
	// root: {
	//   color: 'inherit',
	//   textDecoration: 'inherit',
	//   '&:hover': {
	//     textDecoration: 'underline',
	//   },
	// },
	// primary: {
	//   color: theme.palette.primary.main,
	// },
	tabContent: {
		// background: theme.palette.background.paper
		padding: theme.spacing.unit * 3,
	}
});

class Customers extends Component {
	// إدارة علاقات العملاء - عالم زيدو
	
	menu = {
		'/customers': 'إحصائيات',
		'/customers/accounts': 'رسائل',
		'/customers/campaigns': 'أسئلة متكررة'
	}

	render() {
		let { classes, match } = this.props;
		let activeItem = '/customers' + (match.params.tab !== undefined ? '/'+match.params.tab:'');
		return (
			<Fragment>
				<Layout
					activeItem="customers"
					title={'خدمة العملاء - عالم زيدو'}
				>
					<Paper>
						<MenuTabs 
							menu={this.menu}
							activeItem={activeItem}
							/>
						<div className={classes.tabContent} >
							<Typography variant="h3">
								قريبا
						</Typography>
						</div>
						<LinearProgress />
					</Paper>
				</Layout>
			</Fragment>
		);
	}
}

Customers.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(Customers));

