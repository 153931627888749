import React, { Component, Fragment } from 'react';
import Layout from '../layout';
import {
	LinearProgress,
	FormControl, InputLabel,
	Select, MenuItem
} from '@material-ui/core';
import {
	LastPage,
	FirstPage,
	ChevronLeft as NextPage,
	ChevronRight as PreviousPage,
} from '@material-ui/icons';
import Helper from '../utils/Helper';
import MaterialTable from 'material-table'

class DailyReports extends Component {
	state = {
		isLoading: true,
		selectedCampaign: null,
		DailyReports: [],
		campaigns: [{
					id: 'all',
				location: 'من كل الحملات',
				description: 'العملاء'
		}],
		campaignNames: {},
	}

	fetchCampaigns = () => {
		Helper.ApiRequest(
			'/crm/campaigns'
		).then(campaigns => {
			campaigns = [...this.state.campaigns, ...campaigns];
			if (campaigns.length > 0)
				this.selectCampaign(campaigns[0].id);
			let { campaignNames } = this.state;
			campaigns.forEach(c => {
				campaignNames[c.id] = `${c.description} - ${c.location}`;
			});
			this.setState({ isLoading: false, campaigns, campaignNames })
		})
	}

	selectCampaign = (selectedCampaign) => {
		this.setState({
			isLoading: true,
			selectedCampaign,
			reports: []
		})
		this.fetchReports(selectedCampaign);
	}

	fetchReports(campaign) {
		this.setState({ isLoading: true });
		Helper.ApiRequest(`/crm/dailyreports/${campaign}`)
			.then((DailyReports) => {
				console.log(DailyReports);
				this.setState({ DailyReports, isLoading: false });
			});
	}

	componentDidMount() {
		this.fetchCampaigns();
	}

	filter = (event) => {
		let selectedCampaign = event.target.value;
		this.selectCampaign(selectedCampaign);
	}

	// إدارة علاقات العملاء - عالم زيدو
	render() {
		let columns = [
			{ title: 'التاريخ', field: 'date', type: 'date', defaultSort:'desc' },
			{ title: 'التسجيلات', field: 'created', type: 'numeric' },
			{ title: 'الحذف', field: 'deleted', type: 'numeric' },
			{ title: 'الحسابات المدفوعة', field: 'paid', type: 'numeric' },
		];
		if (Helper.getRole() === "admin") {
			columns.push({ title: 'عمليات الدفع', field: 'payment', type: 'numeric' });
			columns.push({ title: 'مستعملي القسائم', field: 'coupons', type: 'numeric' });
		}

		return (
			<Fragment>
				<Layout
					activeItem="DailyReports"
					title={'التقارير اليومية - عالم زيدو'}
				>
					{this.state.isLoading && <LinearProgress />}
					{
						!this.state.isLoading &&
						<FormControl style={{ minWidth: 200 }}>
							<InputLabel htmlFor="filter-simple">الحملات</InputLabel>
							<Select
								value={this.state.selectedCampaign}
								onChange={this.filter}
								inputProps={{
									name: 'filter',
									id: 'filter-simple',
								}}
							>
								{
									this.state.campaigns.map(item => {
										return <MenuItem key={item.id} value={item.id}>{`${item.description} - ${item.location}`}</MenuItem>
									})
								}
							</Select>
						</FormControl>
					}
					{
						!this.state.isLoading &&
						<MaterialTable
							columns={columns}
							data={this.state.DailyReports.reports}
							title={`تقارير : ${this.state.campaignNames[ this.state.selectedCampaign ]}`}
							options={{
								columnsButton: true,
								exportButton: true,
							}}
							localization={{
								pagination: {
									labelDisplayedRows: '{from}-{to} من أصل {count}', // {from}-{to} of {count}
									labelRowsPerPage: 'عدد الأسطر في الصفحة:', // Rows per page:
									firstAriaLabel: 'الأولى', // First Page
									firstTooltip: 'الصفحة الأولى', // First Page
									previousAriaLabel: 'السابقة', // Previous Page
									previousTooltip: 'الصفحة السابقة', // Previous Page
									nextAriaLabel: 'التالي', // Next Page
									nextTooltip: 'الصفحة التالية', // Next Page
									lastAriaLabel: 'الأخيرة', // Last Page
									lastTooltip: 'الصفحة الأخيرة', // Last Page
								},
								toolbar: {
									// nRowsSelected: '{0} Zeile(n) ausgewählt', // {0} row(s) selected
									showColumnsTitle: 'نخصيص', // Show Columns
									showColumnsAriaLabel: 'اختيار الأعمدة', // Show Columns
									exportTitle: 'تنزيل', // Export
									exportAriaLabel: 'تنزيل', // Export
									exportName: 'تنزيل CSV', // Export as CSV
									searchTooltip: 'بحث', // Search
								},
								header: {
									actions: 'Aktionen', // Actions
								},
								body: {
									emptyDataSourceMessage: 'لا يوجد تسجيلات', // No records to display
									filterRow: {
										filterTooltip: 'الفلترة', // Filter
									},
								},
							}}
							icons={{
								LastPage: FirstPage,
								FirstPage: LastPage,
								NextPage,
								PreviousPage
							}}
						/>
					}
				</Layout>
			</Fragment>
		);
	}
}

export default DailyReports;
