import React, { Component, Fragment } from 'react';
import withRoot from '../../withRoot';
import { Tables } from 'zido-world-material-components'
import Helper from '../../utils/Helper'
import { Grid, LinearProgress, Button } from '@material-ui/core';
import { withRouter } from 'react-router'


const headers = [
	{ id: 'name', type: "string", disablePadding: true, label: 'الاسم' },
	{ id: 'email', type: "string", disablePadding: true, label: 'البريد' },
	{ id: 'kids', type: "number", disablePadding: true, label: 'الأطفال' },
	{ id: 'date', type: "date", disablePadding: true, label: 'تاريخ الاشتارك' },
	{ id: 'status', type: "string", disablePadding: true, label: 'وضعية الحساب' },
	{ id: 'actions', type: "string", disablePadding: true, label: '' },
];


class Accounts extends Component {
	// إدارة علاقات العملاء - عالم زيدو

	state = {
		data: [],
		isLoading: true,
	}

	link = (goTo) => {
		let {history} = this.props;
		history.push( goTo );
	}

	componentDidMount() {
		Helper.ApiRequest(
			'/crm/accounts'
		).then(data => {
			data.forEach( (row, idx) => {
				data[idx]["actions"] = <Button color="primary" variant="outlined" 
							onClick={() => this.link(`/accounts/${row.id}`)}>تفاصيل</Button>;
				if( !row.date ) return;
				let d = new Date(row.date).getTime();
				data[idx].date = d;
			})
			this.setState({ isLoading: false, data })
		})
	}

	render() {
		return (
			<Fragment>
				{
					this.state.isLoading &&
					<Grid item xs>
						<LinearProgress />
					</Grid>
				}
				{
					!this.state.isLoading &&
					<Tables
						actionColums="copy"
						title="لائحة العملاء"
						headers={headers}
						orderBy={'name'}
						data={this.state.data}
					/>
				}
			</Fragment>
		);
	}
}

// Accounts.propTypes = {
// 	classes: PropTypes.object.isRequired
// };

export default withRoot(withRouter(Accounts));

