import React, { Component, Fragment } from 'react';
import withRoot from '../../withRoot';
import { Tables } from 'zido-world-material-components'
import Helper from '../../utils/Helper'
import { DashboardCards } from 'zido-world-material-components'
import { Grid, LinearProgress, List, ListItem, ListItemText, Typography, Divider, Paper, AppBar, Tabs, Tab } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const headers = [
	{ id: 'name', type: "string", disablePadding: true, label: 'الاسم' },
	{ id: 'email', type: "string", disablePadding: true, label: 'البريد' },
	{ id: 'kids', type: "number", disablePadding: true, label: 'الأطفال' },
	{ id: 'date', type: "date", disablePadding: true, label: 'تاريخ الاشتارك' },
	{ id: 'status', type: "string", disablePadding: true, label: 'وضعية الحساب' },
];
const indexes = [{
	title: 'تسجيلات اليوم',
	index: "today",
}, {
	title: 'تسجيلات أمس',
	index: "yesterday",
}, {
	title: 'عدد العملاء',
	index: "total",
}, {
	title: 'الحسابات المدفوعة',
	index: "active",
}, {
	title: 'الحسابات المجانية',
	index: "suspended",
}, {
	title: 'الحسابات غير المفعلة',
	index: "inactive",
}, {
	title: 'الحسابات المغلقة',
	index: "unsubscribes",
}, {
	title: 'مجموع الأطفال',
	mainIndex: 'kids',
	list: [
		{
			label: 'م. الأول',
			index: "level1",
		},
		{
			label: 'م. الثاني',
			index: "level2",
		},
		{
			label: 'م. الثالث',
			index: "level3",
		}
	],
},
]


class Campaigns extends Component {
	// إدارة علاقات العملاء - عالم زيدو

	state = {
		campaigns: [],
		data: [],
		isLoading: true,
		cards: [],
		selectedCampaign: null,
		tab: 0,
	}

	fetchCards = (campaign) => {
		Helper.ApiRequest(
			`/crm/campaigns/${campaign}`
		).then(result => {
			let { counters, data } = result;
			let cards = []
			indexes.forEach(item => {
				let { title, index, mainIndex } = item;
				if (index)
					cards.push({
						title,
						value: counters[index],
					})
				if (mainIndex) {
					let list = [];
					item.list.forEach(it => {
						list.push(
							{
								label: it.label,
								value: counters[mainIndex][it.index],
							}
						)
					});
					cards.push({
						title: title,
						list,
					})
				}
			})
			this.setState({ cards, data, isLoading: false });
		})
	}

	fetchCampaigns = () => {
		Helper.ApiRequest(
			'/crm/campaigns'
		).then(campaigns => {
			if (campaigns.length > 0)
				this.selectCampaign(campaigns[0]);

			this.setState({ isLoading: false, campaigns })
		})
	}

	selectCampaign = (selectedCampaign) => {
		this.setState({
			isLoading: true,
			selectedCampaign,
			cards: []
		})
		this.fetchCards(selectedCampaign.id);
	}

	componentDidMount() {
		this.fetchCampaigns();
	}

	selectTab = (event, tab) => {
		this.setState({ tab })
	}

	render() {
		let { tab } = this.state;
		return (
			<Fragment>
				<Grid container>
					<Grid item md={3} sm={4} xs={12}>
						<Typography variant="h5" >
							الحملات
							</Typography>
						<Paper style={{ margin: "0 20px" }}>
							<List component="nav" color="primary" >
								{
									this.state.campaigns.map((campaign, idx) => {
										return (
											<ListItem
												disabled={this.state.isLoading}
												button
												key={idx}
												onClick={() => this.selectCampaign(campaign)}
												selected={this.state.selectedCampaign.id === campaign.id}
											>
												<ListItemText primary={campaign.description} secondary={campaign.location} />
											</ListItem>
										)
									})
								}
							</List>
						</Paper>
					</Grid>
					<Grid item container direction="column" md={9} sm={8} xs={12}>
						{
							this.state.isLoading ?
								<Fragment>
									<Typography variant="subtitle1" color="textSecondary" >
										جاري تحميل البيانات
									</Typography>
									<Divider />
									<LinearProgress />
								</Fragment>
								:
								<Fragment>
									<Typography variant="subtitle1" color="textSecondary" >
										التسجيلات الواردة من حملة :
										{
											`${this.state.selectedCampaign.description} - ${this.state.selectedCampaign.location}`
										}
									</Typography>
									<Divider />
									{
										Helper.getRole() === "admin" &&
										<AppBar position="static" style={{ margin: "10px 0" }}>
											<Tabs value={tab} onChange={this.selectTab}>
												<Tab label="الإحصائيات" />
												<Tab label="العملاء" />
											</Tabs>
										</AppBar>
									}
									{
										tab === 0 &&
										<Grid item container >
											{
												this.state.cards.map((card, idx) =>
													<Grid item md={3} key={idx} sm={4} xs={6}>
														<DashboardCards
															{...card}
															raised={false}
															color={green[50]}
														/>
													</Grid>
												)
											}
										</Grid>

									}
									{tab === 1 &&
										<Grid item container >
											<Tables
												actionColums="copy"
												title="لائحة العملاء"
												headers={headers}
												orderBy={'name'}
												data={this.state.data}
											/>
										</Grid>
									}
								</Fragment>
						}
					</Grid>
				</Grid>
			</Fragment>
		);
	}
}

export default withRoot(Campaigns);

