import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';
import { CssBaseline } from '@material-ui/core';
import Header from './Header';
import SideBar from './SideBar';

const styles = theme => ({
	root: {
		display: 'flex',
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
	},
});

class Layout extends React.Component {
	state = {
		mobileOpen: false,
	};

	handleDrawerToggle = () => {
		this.setState(state => ({ mobileOpen: !state.mobileOpen }));
	};

	render() {
		const { classes, title, activeItem } = this.props;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<Header 
					title={title}
					handleDrawerToggle={this.handleDrawerToggle}
					/>
				<SideBar 
					handleDrawerToggle={this.handleDrawerToggle}
					mobileOpen={this.state.mobileOpen}
					activeItem={activeItem}
				/>
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{/* this is the main content of the page */}
					{this.props.children}
				</main>
			</div>
		);
	}
}

Layout.propTypes = {
	classes: PropTypes.object.isRequired,
	// Injected by the documentation to work in an iframe.
	// You won't need it on your project.
	container: PropTypes.object,
	theme: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles, { withTheme: true })(Layout));
