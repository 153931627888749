import React, { Component, Fragment } from 'react';
import Layout from '../layout';
import { FormControl, MenuItem, LinearProgress, InputLabel, Select, Grid, Paper } from '@material-ui/core';
import Helper from '../utils/Helper';
import { Publication } from '../containers'

class Publications extends Component {
	state = {
		isLoading: true,
		publications: [],
		filtered: [],
		condition: 'pending',
	}

	refresh() {
		this.setState({ isLoading: true });
		Helper.ApiRequest(`/publications`)
			.then((publications) => {
				this.setState({ publications, isLoading: false });
				this.filter();
			});
	}

	componentDidMount() {
		this.refresh()
	}

	unmountPub = (idx) => {
		this.refresh() // @TODO: use splice instead of refresh
	}

	filter = (event) => {
		let condition = !event ? 'pending' : event.target.value;
		this.setState({ condition });
	}
	isFiltered = (item) => {
		let ret = false;
		let { condition } = this.state;
		switch (condition) {
			case 'pending':
				if (!item.confirmed && !item.message) ret = true;
				break;
			case 'rejected':
				if (!item.confirmed && item.message) ret = true;
				break;
			case 'approved':
				ret = item.confirmed === true;
				break;
			default: ret = true;
		}
		return ret;
	}

	// إدارة علاقات العملاء - عالم زيدو
	render() {
		if( Helper.getRole() !== "admin" ) return "";
		return (
			<Fragment>
				<Layout
					activeItem="publications"
					title={'مشاركات الأطفال - عالم زيدو'}
				>
					{this.state.isLoading && <LinearProgress />}

					<FormControl style={{ minWidth: 200 }}>
						<InputLabel htmlFor="filter-simple">إظهار</InputLabel>
						<Select
							value={this.state.condition}
							onChange={this.filter}
							inputProps={{
								name: 'filter',
								id: 'filter-simple',
							}}
						>
							<MenuItem value="pending">المتعلقة</MenuItem>
							<MenuItem value="rejected">المرفوضة</MenuItem>
							<MenuItem value="approved">الموافق عليها</MenuItem>
							<MenuItem value="all">كل المشاركات</MenuItem>
						</Select>
					</FormControl>
					<Paper>
						<Grid container>
							{this.state.publications.map((pub, idx) => {
								if (!this.isFiltered(pub)) return '';
								return <Grid item sm key={idx}>
									<Publication
										unmount={() => this.unmountPub(idx)}
										content={pub}
									/>
								</Grid>
							})}
						</Grid>
					</Paper>
				</Layout>
			</Fragment>
		);
	}
}

export default Publications;
