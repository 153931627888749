import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';
import { withRouter } from 'react-router';
import { FieldSet } from 'zido-world-material-components';
import Helper from '../utils/Helper';
import {
	Typography, IconButton, Tooltip, Dialog, DialogTitle,
	DialogContent, DialogContentText, TextField, DialogActions, Button
} from '@material-ui/core';
import { orange, red, green } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete'
import ApproveIcon from '@material-ui/icons/ThumbUp'
import RejectIcon from '@material-ui/icons/ThumbDown'

const styles = theme => ({
	accepted: {
		padding: theme.spacing.unit,
		borderBottom: `2px solid ${green[900]}`,
		color: green[900],
		display: 'inline-block',
	},
	pending: {
		padding: theme.spacing.unit,
		borderBottom: `2px solid ${orange[900]}`,
		color: orange[900],
		display: 'inline-block',
	},
	rejected: {
		padding: theme.spacing.unit,
		borderBottom: `2px solid ${red[900]}`,
		color: red[900],
		display: 'inline-block',
	},
	rejectBtn: {
		background: orange[500],
		color: 'white',
		margin: theme.spacing.unit,
		'&:hover': {
			color: orange[500]
		}
	},
	approveBtn: {
		background: green[500],
		color: 'white',
		margin: theme.spacing.unit,
		'&:hover': {
			color: green[500]
		}
	},
	deleteBtn: {
		background: red[500],
		color: 'white',
		margin: theme.spacing.unit,
		marginLeft: 'auto',
		'&:hover': {
			color: red[500],
		}
	},
	margin: {
		margin: theme.spacing.unit,
	},
});

class Publication extends Component {

	state = {
		content: this.props.content,
		openPreview: false,
		openConfirm: false,
		confirm: '',
	}

	link = (goTo) => {
		let { history } = this.props;
		history.push(goTo);
	}


	updatePublication = (data) => {
		let { content } = this.state;
		const url = '/publications/confirm/' + content._id;
		return Helper.ApiRequest(url, {
			method: 'PUT',
			body: JSON.stringify(data)
		}).then((result) => {
			if (result) {
				this.setState({
					content: {
						...content,
						...data
					}
				});
			}
		})
	}

	// rejectPublication = (publication) => {
	// 	const imageData = Helper.MediaURL(publication.thumbnail);
	// 	const getData = this.getData
	// 	// we openPreview a popup with a textarea.
	// 	swal({
	// 		title: MESSAGES.rejectPopupTitle,
	// 		text: '',
	// 		type: 'question',
	// 		html: `<div> ${MESSAGES.rejectPopupText} </div><br/><Image class="confirm-thumbnail" src="${imageData}" thumbnail />`,
	// 		showCancelButton: true,
	// 		input: 'textarea',
	// 		inputPlaceholder: MESSAGES.rejectPopupPlaceholder,
	// 		inputClass: 'custom-input-class',
	// 		confirmButtonText: MESSAGES.reject,
	// 		cancelButtonText: MESSAGES.cancel,
	// 		inputValidator: value => !value && MESSAGES.rejectPopupValidator,

	// 	}).then((result) => {
	// 		// result.value holds the input
	// 		if (!result.value) return;
	// 		updatePublication(publication._id, { confirmed: false, message: result.value })
	// 			.then(getData)
	// 			.catch((err) => { Helper.log(err) })
	// 	})
	// }

	togglePreview = () => {
		let { openPreview } = this.state;
		this.setState({ openPreview: !openPreview });
	}

	toggleConfirm = (confirm) => {
		let { openConfirm } = this.state;
		this.setState({ openConfirm: !openConfirm, confirm });
	}

	onConfirmClose = (reason) => {
		if (reason === undefined) return this.toggleConfirm();
		if (this.state.confirm === 'reject')
			this.updatePublication({ confirmed: false, message: reason })
				.then(() => this.toggleConfirm());
		else {
			if (reason === true) this.delete();
		}
	}

	delete = () => {
		let { content } = this.state;
		let url = '/publications/' + content._id;
		Helper.ApiRequest(url, { method: 'DELETE' })
			.then(() => {
				let { unmount } = this.props;
				if (unmount)
					unmount.apply();
				this.toggleConfirm()
			})
	}

	render() {
		let { classes } = this.props;
		let { content } = this.state;
		let rejectable = content.confirmed ? true:
										content.message ? false:true;
		// let { classes, menu, activeItem } = this.props;
		return (
			<Fragment>
				<FieldSet
					media={Helper.MediaURL(content.content)}
					mediaAction={this.togglePreview}
					title={content.kid.firstName ? content.kid.firstName : 'تفاصيل المنشور'}
					color={
						content.confirmed ? green[50]
							: content.message ? red[50]
								: orange[50]
					}
					list={[{
						label: "وصف المنشر",
						value: content.description,
					}, {
						label: "حالة المنشر",
						value:
							content.confirmed ?
								<Typography className={classes.accepted} variant="body2"> منشور </Typography>
								: content.message ? <Typography className={classes.rejected} variant="body2" color="error"> مرفوض </Typography>
									: <Typography className={classes.pending} variant="body2" > معلق </Typography>
					}
					]}
					actions={
						<Fragment>
							{
								!content.confirmed &&
								<Tooltip title="تأكيد" aria-label="تأكيد">
									<IconButton aria-label="Delete" size="" className={classes.approveBtn}
										onClick={() => this.updatePublication({ confirmed: true })}>
										<ApproveIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							}
							{
								rejectable &&
								<Tooltip title="رفض" aria-label="رفض">
									<IconButton aria-label="Delete" size="" className={classes.rejectBtn}
										onClick={() => this.toggleConfirm('reject')}>
										<RejectIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							}

							<Tooltip title="حذف" aria-label="حذف">
								<IconButton aria-label="Delete" className={classes.deleteBtn}
									onClick={() => this.toggleConfirm('delete')}>
									<DeleteIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						</Fragment>
					}
				/>
				<ModalPreview
					url={Helper.MediaURL(content.content)}
					onClose={this.togglePreview}
					open={this.state.openPreview}
				/>
				<ConfirmModal
					open={this.state.openConfirm}
					onClose={this.onConfirmClose}
					confirm={this.state.confirm}
				/>
			</Fragment>
		)
	}
}


Publication.propTypes = {
	classes: PropTypes.object.isRequired,
	content: PropTypes.object,
};


export default withRoot(withStyles(styles, { withTheme: true })(withRouter(Publication)));


class ModalPreview extends Component {
	render() {
		const { onClose, title, url, classes, ...others } = this.props;
		return (
			<Dialog onClose={onClose} maxWidth="lg" {...others}  >
				<div>
					<img src={url} alt="preview" style={{ maxWidth: '100%', maxHeight: '100%', border: '5px solid white' }} />
				</div>
			</Dialog>
		);
	}
}


class ConfirmModal extends Component {

	state = {
		value: '',
		error: false,
	}

	handleClose = (reject) => {
		let { confirm } = this.props;
		if (confirm === 'delete') {
			return this.props.onClose.call(null, true);
		}
		if (reject === true) {
			let { value } = this.state;
			if (!value) return this.setState({ error: true });
			this.props.onClose.call(null, value);
		}
		else {
			this.setState({ value: '', error: false })
			this.props.onClose.call(null)
		}
	}

	handleChange = (event) => {
		let { value } = event.target;
		this.setState({ value, error: false })
	}

	render() {
		let { confirm } = this.props;
		return (
			<Dialog
				open={this.props.open}
				onClose={this.handleClose}
				aria-labelledby="form-dialog-title"
				fullWidth
			>
				<DialogTitle id="form-dialog-title">المرجو التأكيد</DialogTitle>
				<DialogContent>
					{
						confirm === 'reject' &&
						<Fragment>
							<DialogContentText>
								أنت على وشك رفض المشاركة, ما هو السبب ؟
							</DialogContentText>
							<TextField
								value={this.state.value}
								onChange={this.handleChange}
								margin="dense"
								label="سبب الرفض"
								type="text"
								multiline
								fullWidth
							/>
							{this.state.error && <Typography variant="caption" color="error">المرجو توضيح سبب الرفض</Typography>}
						</Fragment>
					}
					{
						confirm === 'delete' &&
						<DialogContentText>
							أنت على وشك حذف المشاركة, المرجو التأكيد ؟
							</DialogContentText>
					}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => this.handleClose(false)} variant="outlined" color="secondary">
						إلغاء
            </Button>
					<Button onClick={() => this.handleClose(true)} size="large" variant="contained" color="primary" >
						تأكيد
            </Button>
				</DialogActions>
			</Dialog>
		)
	}
}