import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import {
	ImportContacts as ReportsIcon,
	Filter as PublicationsIcon,
	CalendarToday as DailyReportsIcon,
} from '@material-ui/icons';
// import CSIcon from '@material-ui/icons/HowToReg';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';
import { withRouter } from 'react-router'
import Helper from '../utils/Helper'

const styles = theme => ({
	toolbar: theme.mixins.toolbar,
});

const drawerWidth = 240;

class MenuDrawer extends Component {

	link = (goTo) => {
		let {history } = this.props;
		history.push( goTo );
	}

	render() {
		let { classes, activeItem } = this.props;
		return (
			<div>
				<div className={classes.toolbar} />
				<Divider />
				<List>
					<ListItem button selected={"reports" === activeItem } onClick={() => this.link('/')}>
						<ListItemIcon><ReportsIcon /></ListItemIcon>
						<ListItemText primary={'تقارير'} />  
					</ListItem>
					{/* <ListItem button selected={"customers" === activeItem } onClick={() => this.link('/customers')}>
						<ListItemIcon><CSIcon /></ListItemIcon>
						<ListItemText primary={'خدمة العملاء'} />  
					</ListItem> */}
					{
						Helper.getRole() === "admin" &&
						<ListItem button selected={"publications" === activeItem } onClick={() => this.link('/publications')}>
							<ListItemIcon><PublicationsIcon /></ListItemIcon>
							<ListItemText primary={'المشاركات'} />  
						</ListItem>
					}
					<ListItem button selected={"DailyReports" === activeItem } onClick={() => this.link('/daily-reports')}>
						<ListItemIcon><DailyReportsIcon /></ListItemIcon>
						<ListItemText primary={'التحديثات اليومية'} />  
					</ListItem>
				</List>
			</div>
		)
	}
}


MenuDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
	// Injected by the documentation to work in an iframe.
	// You won't need it on your project.
	container: PropTypes.object,
	theme: PropTypes.object.isRequired,
};

MenuDrawer = withRoot(withStyles(styles, { withTheme: true })(withRouter(MenuDrawer)));

export {
	drawerWidth,
	MenuDrawer
}

