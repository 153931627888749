import React, { Component, Fragment } from 'react';
import Layout from '../../layout';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import withRoot from '../../withRoot';
import { MenuTabs } from 'zido-world-material-components'
import Helper from '../../utils/Helper'

// load partials
import Dashboard from './Dashboard';
import Accounts from './Accounts';
import Profile from './Profile';
import Campaigns from './Campaigns';
import Kid from './Kid';


const styles = theme => ({
	// root: {
	//   color: 'inherit',
	//   textDecoration: 'inherit',
	//   '&:hover': {
	//     textDecoration: 'underline',
	//   },
	// },
	// primary: {
	//   color: theme.palette.primary.main,
	// },
	tabContent: {
		// background: theme.palette.background.paper
		padding: theme.spacing.unit * 3,
	}
});

class Reports extends Component {
	// إدارة علاقات العملاء - عالم زيدو

	menu = {
		'/': 'إحصائيات',
		'/campaigns': 'حملات',
	}

	render() {
		let { classes, match } = this.props;
		let display;
		let activeItem = '/'
		if( Helper.getRole() === "admin" ) this.menu['/accounts'] = 'العملاء';

		switch (match.params.tab) {
			// case 'kids':
			// 	activeItem += 'accounts';
			// 	display = <Kid id={match.params.id} />
			// 	break;
			case 'accounts':
				activeItem += 'accounts';
				display = (match.params.id) ? <Profile id={match.params.id} /> : <Accounts />
				if( match.params.report === 'report')
					display = <Kid id={match.params.kid} parent={match.params.id} />
				break;
			case 'campaigns':
				activeItem += 'campaigns';
				display = <Campaigns />
				break;
			default:
				display = <Dashboard />
		}
		return (
			<Fragment>
				<Layout
					activeItem="reports"
					title={'تقارير العملاء - عالم زيدو'}
				>
					<Paper>
						<MenuTabs
							menu={this.menu}
							activeItem={activeItem}
						/>
						<div className={classes.tabContent} >
							{
								display
							}
						</div>
					</Paper>
				</Layout>
			</Fragment>
		);
	}
}

Reports.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(Reports));

