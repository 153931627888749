import React, { Component, Fragment } from 'react';
import {
  Grid, LinearProgress, Typography, Button, withStyles,
  ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Divider,
} from '@material-ui/core';
import withRoot from '../../withRoot';
import Helper from '../../utils/Helper'
import { FieldSet } from 'zido-world-material-components'
import { Publication } from '../../containers'
import { green, orange, purple } from '@material-ui/core/colors';
import BackIcon from '@material-ui/icons/ChevronRight'
import { withRouter } from 'react-router'
import { ClipBoardCopy } from 'zido-world-material-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/DoneAll'
import PendingIcon from '@material-ui/icons/HourglassEmpty'


const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

class Kid extends Component {

  headlines = {
    displayName: 'الاسم',
    gender: (item) => {
      return {
        label: 'الجنس',
        value: item === "male" ? 'ولد' : 'بنت',
      }
    },
    level: (item) => {
      return {
        label: 'المستوى',
        value: item === 1 ? 'الأول' :
          item === 2 ? 'الثاني' : 'الثالث',
        options: {
          color: item === 1 ? green[50] :
            item === 2 ? orange[50] : purple[50],
        }
      }
    },
    lastSeen: (item) => {
      return {
        label: 'آخر ظهور',
        value: item ? new Date(item).toLocaleDateString() : '',
      }
    },
  }

  buildRows = (set, data) => {
    let row = {
      options: {},
      list: [],
    };
    Object.keys(set).forEach(item => {
      if (typeof set[item] === 'string')
        row.list.push({
          label: set[item],
          value: data[item],
        })
      if (typeof set[item] === 'function') {
        let { label, value, options } = set[item].call(this, data[item])
        row.options = {
          ...row.options,
          ...options,
        }
        if (label && value) row.list.push({ label, value });
      }
    })
    return row;
  }

  state = {
    isLoading: true,
    parent: this.props.parent,
    card: [],
    details: {}
  }

  link = (goTo) => {
    let { history } = this.props;
    history.push(goTo);
  }

  refresh = () => {
    let { id, parent } = this.props;
    this.setState({ isLoading: true })
    Helper.ApiRequest(
      `/accounts/${parent}/reports/${id}`
    ).then(details => {
      let card = this.buildRows(this.headlines, details);
      this.setState({ card, details, isLoading: false })
    })
  }

  componentDidMount() {
    this.refresh()
  }

  unmountPub = (idx) => {
    this.refresh() // @TODO: use splice instead of refresh
  }

  render() {
    let { id, classes } = this.props;
    if (this.state.isLoading) return <LinearProgress />
    return (
      <Fragment>
        {
          this.state.parent &&
          <Button variant="contained" className={classes.margin}
            onClick={() => this.link(`/accounts/${this.props.parent}`)}
          >
            <BackIcon />
            الرجوع إلى الأب
          </Button>
        }
        <Typography variant="h5" style={{ display: 'inline-block' }} >
          معلومات الطفل
        </Typography>
        <Typography variant="h6" style={{ display: 'inline-block' }} className={classes.margin}>
          {id}
          <ClipBoardCopy text={id} />
        </Typography>

        <Grid container>
          <Grid item lg={4} md={6} sm={12}>
            <FieldSet
              title={'بطاقة الطفل'}
              list={this.state.card.list}
              {...this.state.card.options}
            />
            <FieldSet
              title={'المشاهدة'}>
              <LinearProgress variant="determinate" value={this.state.details.progress} className={classes.margin} />
              {
                this.state.details.episodes.map((row, idx) => ( // title // completed // eduTargets
                  <ExpansionPanel key={idx}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>
                        {
                          row.completed ?
                            <DoneIcon color="primary" style={{ margin: '0 5px' }} />
                            : <PendingIcon color="secondary" style={{ margin: '0 5px' }} />
                        }
                        {row.title}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ display: 'block' }}>
                      <Typography variant="subtitle1" color="primary">الأهذاف التربوية</Typography>
                      <Divider style={{ marginBottom: 10 }} />
                      {
                        row.eduTargets.map((item, idx) => {
                          return <Typography key={idx} variant="body1"> - {item}</Typography>
                        })
                      }
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))
              }

            </FieldSet>
          </Grid>
          <Grid item lg md sm>
            <FieldSet
              title={'المشاركات'}>
              <Grid container>
                {
                  this.state.details.publications.map((pub, idx) =>
                    <Grid item sm key={idx}>
                      <Publication
                        unmount={() => this.unmountPub(idx)}
                        content={pub}
                      />
                    </Grid>
                  )
                }
              </Grid>
            </FieldSet>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withRouter(withRoot(withStyles(styles)(Kid)));


