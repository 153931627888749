import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Hidden, Drawer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MenuDrawer, drawerWidth } from './components';
import withRoot from '../withRoot';

const styles = theme => ({
	drawer: {
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	drawerPaper: {
		width: drawerWidth,
	},
});

class SideBar extends Component {

	state = {
		mobileOpen: this.props.mobileOpen,
	}

	componentDidUpdate(prevProps) {
		let {mobileOpen} =  prevProps;
		if ( mobileOpen !== this.props.mobileOpen)
			this.setState({
				mobileOpen: this.props.mobileOpen
			})
	}

	render() {
		let { classes, handleDrawerToggle, activeItem } = this.props;
		return (
			<Fragment>
				<nav className={classes.drawer}>
					<Hidden mdUp implementation="css">
						<Drawer
							container={this.props.container}
							variant="temporary"
							open={this.state.mobileOpen}
							onClose={handleDrawerToggle}
							classes={{
								paper: classes.drawerPaper,
							}}
						>
							<MenuDrawer activeItem={activeItem} />
						</Drawer>
					</Hidden>
					<Hidden smDown implementation="css">
						<Drawer
							classes={{
								paper: classes.drawerPaper,
							}}
							variant="permanent"
							open
						>
							<MenuDrawer activeItem={activeItem} />
						</Drawer>
					</Hidden>
				</nav>
			</Fragment>
		);
	}
}

SideBar.propTypes = {
	classes: PropTypes.object.isRequired,
	// Injected by the documentation to work in an iframe.
	// You won't need it on your project.
	container: PropTypes.object,
	theme: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles, { withTheme: true })(SideBar));